import { useStaticQuery, graphql } from 'gatsby';

// TODO: Add Filtering and sorts

export const useMethodsList = () => {
  const { allGsSheet1Methods } = useStaticQuery(
    graphql`
      query dataMethodsQuery {
        allGsSheet1Methods(sort: {fields: methodDescription}) {
          totalCount
          edges {
            node {
              methodAbbreviation
              methodDescription
            }
          }
        }
    }
    `
  );
  return allGsSheet1Methods;
};
