import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './exportbutton.css';

export const ExportButton: React.FC<{
  rows: any
}> = ({rows}) => {

  const yearAdjustedValues = (rows[0]) ? `Adjusted Values US$ ${rows[0].node.yearAdjustedValues}` : 'Adjusted Values US$ 2019';

  const exportResults = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const marginLeft = 40;
    const marginTop = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    const title = 'BlueValue Data';

    const headers = [[
      'ID',
      'Type of habitat',
      'Service',
      yearAdjustedValues,
      'Units',
      'Country',
      'State',
      'Method',
      'Author',
      'Link to source']
    ];

    const data = rows.map((obj, index) => ([
      index + 1,
      obj.node.typeOfHabitat,
      obj.node.service,
      obj.node.adjustedValues,
      obj.node.units,
      obj.node.country,
      obj.node.state,
      obj.node.method,
      obj.node.author,
      obj.node.linkToSource,
    ]));

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: { cellPadding: 4, fontSize: 9 },
      columnStyles: {
        0: {cellWidth: 20},
        1: {cellWidth: 50},
        2: {cellWidth: 60},
        3: {cellWidth: 130},
        4: {cellWidth: 50},
        5: {cellWidth: 50},
        6: {cellWidth: 50},
        7: {cellWidth: 50},
        8: {cellWidth: 70},
        9: {cellWidth: 'wrap'},
      },
      margin: {
        top: 50,
      },
    };

    var d = new Date();


    doc.text(title, marginLeft, marginTop);
    doc.autoTable(content);
    let footer =
    'Harte Research Institute for Gulf of Mexico Studies. 2020.\r\n'+
    'BlueValue: Resource Database for Ecosystem Services.Texas A&M University-Corpus Christi. World Wide Web electronic publication.\r\n'+
    'http://www.bluevalue.org, '+d.toDateString();

    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFont('helvetica', 'italic');
      doc.setFontSize(8);
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(footer, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 30, {
          align: 'center'
        });
      }
    };
    addFooters(doc);

    var datestring = ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate()).slice(-2) + d.getFullYear();
    var filename = 'BlueValue-Search-Results-' + datestring + '.pdf';
    doc.save(filename);
  };


  return (
    <button onClick={() => exportResults()} className="btn btn-thin btn-green-ghost" title="Export as PDF" name="Export as PDF">PDF</button>
  );
};
