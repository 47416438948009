import * as React from 'react';
import Pagination from 'rc-pagination';

export const SearchResults: React.FC<{
  currentData: any;
  offset: number;
  currentPage: number;
  setCurrentPage: any;
  setOffset: any;
  rowsLength: number;
}> = ({ currentData, offset, currentPage, setCurrentPage, setOffset, rowsLength }) => {

  // Paginations.
  const pageLimit = 10;
  const onPage = page => {
    setCurrentPage(page);
    setOffset(page * pageLimit - pageLimit);
  };

  const yearadjustedvalues = currentData[0].node.yearadjustedvalues;
  const unitsyear = currentData[0].node.unitsYear;

  return (
    <>
      <div className="table-scroll rounded-lg table-wrapper bg-white">
        <table className="table-fixed table--search-results">
          <thead>
            <tr>
              <th className="px-2 py-2 table--id">ID</th>
              <th className="px-4 py-2">Type of habitat</th>
              <th className="px-4 py-2">Service</th>
              <th className="px-4 py-2">Adjusted Values US$ { unitsyear }</th>
              <th className="w-1/6 px-4 py-2">Units</th>
              <th className="px-4 py-2">Country</th>
              <th className="w-1/6 px-4 py-2">State</th>
              <th className="px-2 py-2 table--method">Method</th>
              <th className="w-2/6 px-4 py-2">Author</th>
              <th className="w-2/6 px-4 py-2">Link to source</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((obj, index) => (
              <tr key={obj.node.id}>
                <td className="border px-2 py-2 table--id">{offset + index + 1}</td>
                <td className="border px-4 py-2">{obj.node.typeOfHabitat}</td>
                <td className="border px-4 py-2">{obj.node.service}</td>
                <td className="border px-4 py-2">{obj.node.adjustedValues}</td>
                <td className="border px-4 py-2">{obj.node.units}</td>
                <td className="border px-4 py-2">{obj.node.country}</td>
                <td className="border px-4 py-2">{obj.node.state}</td>
                <td className="border px-2 py-2 table--method">{obj.node.method}</td>
                <td className="border px-4 py-2">{obj.node.author}</td>
                <td className="border px-4 py-2 break-all">{obj.node.linkToSource}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={onPage}
        current={currentPage}
        total={rowsLength}
        pageSize={pageLimit}
        defaultCurrent={1}
      />
    </>
  );
};
