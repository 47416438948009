import * as React from 'react';
import { useState, useEffect } from 'react';
import { SearchForm } from '../components/searchform/searchform';
import { ExportButton } from '../components/exportbutton/exportbutton';
import { useDataList } from '../hooks/data-list';
import { SideSearch } from '../components/searchform/sidesearch/sidesearch';
import { SearchResults } from '../components/searchform/searchResults/searchresults';
import '../styles/rc-pagination-default.css';
import { ExportCsvButton } from '../components/csvexportbutton/csvexportbutton';
import Helmet from 'react-helmet';

const ResultsPage: React.FC<{
  edges: {
    node: {
      id: string;
      typeOfHabitat: string;
      author: string;
      country: string;
      linkToSource: string;
      adjustedValues: string;
      units: string;
      service: string;
      state: string;
      method: string;
    };
  }[];
  location: any;
}> = ({ location }) => {
  const { edges } = useDataList();
  let data = edges;

  let initialFilters = { types: [], services: [] };
  // When the offset or the filters change set new results.
  location.state && location.state.filters
    ? (initialFilters = {
        types: location.state.filters.types,
        services: location.state.filters.services,
      })
    : '';

  const [filters, setFilters] = useState(initialFilters);
  const [sideSearchFilters, setSideSearchFilters] = useState();

  const services = data
    .map((row: { node: { service: any } }) => row.node.service.trim())
    .sort();

  const types = data
    .map((row: { node: {typeOfHabitat: any } }) =>
      row.node.typeOfHabitat.trim()
    )
    .sort();
  // Paginations.
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  // Filtering data.
  let rows = data
    .filter(
      (row: { node: { typeOfHabitat: any } }) =>
        filters.types.indexOf(row.node.typeOfHabitat) >= 0 ||
        !filters.types.length
    )
    .filter(
      (row: { node: { service: any } }) =>
        // When the intersection of the filters and the values of the row separated by commas is not empty
        filters.services.filter(
          // Split by commas and trim the data on the row.
          filterItem => row.node.service.split(',').join(' and ').split(/\band\b/).map(item=>item.trim())
          // Wether the current filter item is include in the row.
          .includes(filterItem)
        // Intersection not empty.
        ).length > 0 ||
        !filters.services.length
    )
    .filter(
      (row: { node: { unit: any } }) =>
        !sideSearchFilters ||
        !sideSearchFilters.unit ||
        sideSearchFilters.unit.includes(row.node.units)
    )
    .filter(
      (row: { node: { country: any } }) =>
        !sideSearchFilters ||
        !sideSearchFilters.countries.length ||
        //sideSearchFilters.countries.includes(row.node.country)
        hasCommonElements(sideSearchFilters.countries, row.node.country)
    )
    .filter(
      (row: { node: { state: string } }) =>
        !sideSearchFilters ||
        !sideSearchFilters.states ||
        !sideSearchFilters.states.length ||
        hasCommonElements(sideSearchFilters.states, row.node.state)
    )
    .filter(
      (row: { node: { method: any } }) =>
        !sideSearchFilters ||
        !sideSearchFilters.methods.length ||
        hasCommonElements(sideSearchFilters.methods, row.node.method)
    );

  function hasCommonElements (methodsFilter : string[], methodsRowStr : string) {
    let commonElements = 0;
    let methodsRow = [];
    methodsFilter = methodsFilter.map(e => e.trim());
    // States only: null values
    if (methodsRowStr) {
      methodsRow = methodsRowStr.split(',');
      methodsRow = methodsRow.map(e => e.trim());
    }
    for(let i = 0; i < methodsFilter.length; i++) {
      for(let j = 0; j < methodsRow.length; j++) {
        if(methodsFilter[i] === methodsRow[j]) {
          commonElements++;
        }
      }
    }
    return commonElements > 0;
  }

  function getFloatedPrice(price: string) {
    let matchePrices = price.match(/(\+|-)?((\d+(\.\d+)?)|(\.\d+))/);
    if (matchePrices && matchePrices[0]) {
      return parseFloat(matchePrices[0]);
    }
  }

  // Change filters values.
  const changeFilter = (filters: any) => {
    setFilters({ types: filters.types, services: filters.services });
  };

  const sideSearchChangeFilters = (sideSearchFilters: any) => {
    setSideSearchFilters({
      range: sideSearchFilters.range,
      unit: sideSearchFilters.unit,
      countries: sideSearchFilters.countries,
      states: sideSearchFilters.states,
      methods: sideSearchFilters.methods,
    });
  };
  const [reset, setReset] = useState(false);

  // Reset filters values.
  const resetFilters = () => {
    initialFilters = { types: [], services: [] };
    setFilters(initialFilters);
    setSideSearchFilters(null);
    setReset(true);
  };

  let pageLimit = 10;
  // When the offset or the filters set new results.
  useEffect(() => {
    setOffset(currentPage * pageLimit - pageLimit);
    setCurrentData(
      Array.prototype.slice.call(rows, offset, offset + pageLimit)
    );
    // eslint-disable-next-line
  }, [offset, filters, sideSearchFilters]);

  // When the offset or the filters set new results.
  useEffect(() => {
    setCurrentPage(1);
    setOffset(0);
  }, [filters, sideSearchFilters]);

  // Mobile hide/show searches
  const [showHeaderSearch, setShowHeaderSearch] = useState(true);
  const [showSideBarSearch, setShowSideBarSearch] = useState(false);
  const [hideSearchs, setHideSearchs] = useState(true);
  const [searchMobileClass, setSearchMobileClass] = useState('show-search');
  const [searchHeaderClass, setSearchHeaderClass] = useState(
    'show-header-search'
  );
  const [searchSidebarClass, setSearchSidebarClass] = useState(
    'hide-sidebar-search'
  );

  useEffect(() => {
    hideSearchs
      ? setSearchMobileClass('hide-search')
      : setSearchMobileClass('show-search');
  }, [hideSearchs]);

  const toggleHeaderMobileSearch = () => {
    setShowHeaderSearch(!showHeaderSearch);
    setShowSideBarSearch(!showSideBarSearch);
    showHeaderSearch
      ? setSearchHeaderClass('hide-header-search')
      : setSearchHeaderClass('show-header-search');
    showSideBarSearch
      ? setSearchSidebarClass('hide-sidebar-search')
      : setSearchSidebarClass('show-sidebar-search');
  };

  const toggleSideMobileSearch = () => {
    setShowHeaderSearch(!showHeaderSearch);
    setShowSideBarSearch(!showSideBarSearch);
    showHeaderSearch
      ? setSearchHeaderClass('hide-header-search')
      : setSearchHeaderClass('show-header-search');
    showSideBarSearch
      ? setSearchSidebarClass('hide-sidebar-search')
      : setSearchSidebarClass('show-sidebar-search');
  };

  return (
    <>
      <Helmet title="Search" />

      <section className="search-wrapper clearfix flex inner">
        <aside
          className={`side--search px-5 ${searchSidebarClass} ${searchMobileClass}`}
        >
          <div className="inner">
            <div className="mobile-update-toggles pb-4">
              <div className="close-button-wrapper">
                <button
                  name="close search toggle"
                  onClick={() => setHideSearchs(true)}
                  className="close-button"
                ></button>
              </div>
              <p>Update Valuations</p>
              <div className="update-buttons">
                <button
                  name="main search toggle"
                  onClick={toggleHeaderMobileSearch}
                  className="mr-2 search rounded"
                ></button>
                <button
                  name="valuations search toggle"
                  onClick={toggleSideMobileSearch}
                  className="mr-2 update rounded"
                ></button>
              </div>
            </div>
            <div className="mt-10 heading-3 valuation-results clearfix">
              {rows.length} Valuations
            </div>
            <form>
              <SideSearch
                datalist={data}
                sideSearchFilters={sideSearchFilters}
                sideSearchChangeFilters={sideSearchChangeFilters}
                setReset={setReset}
                reset={reset}
                setHideSearchs={setHideSearchs}
                hideSearchs={hideSearchs}
              />
            </form>
          </div>
        </aside>

        <main className="search--main px-5">
          <button
            name="toggle search"
            onClick={() => setHideSearchs(!hideSearchs)}
            className="btn btn-plain search-mobi--toggle mr-2 search rounded"
          >
            Toggle Search
          </button>
          <section
            id="mainSearch"
            className={`header--search clearfix ${searchHeaderClass} ${searchMobileClass}`}
          >
            <div className="inner">
              <div className="mobile-update-toggles pb-4">
                <div className="close-button-wrapper">
                  <button
                    name="close search"
                    onClick={() => setHideSearchs(true)}
                    className="close-button"
                  ></button>
                </div>
                <p>Search again</p>
                <div className="update-buttons">
                  <button
                    name="main search toggle"
                    onClick={toggleHeaderMobileSearch}
                    className="mr-2 search rounded"
                  ></button>
                  <button
                    name="valuations search toggle"
                    onClick={toggleSideMobileSearch}
                    className="mr-2 update rounded"
                  ></button>
                </div>
              </div>

              <form>
                <SearchForm
                  initialfilters={initialFilters}
                  filters={filters}
                  changeFilter={changeFilter}
                  services={services}
                  types={types}
                />
                <span className="text-left select-more">
                  You can select more than one option.
                </span>
              </form>

              <button
                name="close search"
                onClick={() => setHideSearchs(true)}
                className="height-12 submit btn btn-green w-6/12 lg:hidden"
              >
                Submit
              </button>
            </div>
          </section>
          <div className="filter--functions">
            <ul className="py-3 list--inline items-center flex justify-end">
              <button
                name="reset search"
                className="btn btn-plain"
                onClick={resetFilters}
              >
                Reset Search
              </button>

              <ExportButton rows={rows} />
              <ExportCsvButton rows={rows} />
            </ul>
          </div>
          {currentData.length ? (
            <SearchResults
              currentData={currentData}
              offset={offset}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setOffset={setOffset}
              rowsLength={rows.length}
            />
          ) : (
            <div>
              <p>
                {' '}
                There are no results for your current search. Please reset and
                select different filters.{' '}
              </p>
            </div>
          )}

          <div className="filter--functions">
            <ul className="py-3 list--inline items-center flex justify-end">
              <button
                name="reset search"
                className="btn btn-plain"
                onClick={resetFilters}
              >
                Reset Search
              </button>
              <ExportButton rows={rows} />
              <ExportCsvButton rows={rows} />
            </ul>
          </div>
        </main>
      </section>
    </>
  );
};

export default ResultsPage;
