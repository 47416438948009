import * as React from 'react';
import { useMethodsList } from '../../../hooks/methods-list';
import './sidesearch.css';
import { useState, useEffect } from 'react';
import Select from 'react-select';
// import InputRange from 'react-input-range';
import '../../../styles/react-input-range.css';

import {
  getFloatedPrices,
  getOptionsList,
  getFormattedOptions,
} from '../searchHelpers';

export const SideSearch: React.FC<{
  datalist: any;
  sideSearchChangeFilters: any;
  setReset: any;
  reset: boolean;
  setHideSearchs: any;
  hideSearchs: boolean;
  methodsData: any;
}> = ({
  datalist,
  sideSearchChangeFilters,
  setReset,
  reset,
  setHideSearchs,
  hideSearchs,
  methodsData,
}) => {
  let prices = getFloatedPrices(datalist);
  let minPrice = 0;
  let maxPrice = Math.max(...prices);
  let initialRanges = { min: minPrice, max: maxPrice };
  const [range, setRang] = useState({
    value: initialRanges,
  });

  const [countriesState, setCountriesState] = useState([]);
  const [statesState, setStatesState] = useState([]);
  const [methodsState, setMethodsState] = useState([]);

  useEffect(() => {
    if (reset) {
      setRang({
        value: initialRanges,
      });
      setCountriesState([]);
      setStatesState([]);
      setMethodsState([]);
    }
  }, [reset]);

  function changePriceRange(value) {
    // Avoid min and max issues.
    value.min = value.min < 0 ? 0 : value.min;
    value.max = value.max > initialRanges.max ? initialRanges.max : value.max;

    if (value !== initialRanges) {
      setRang({
        value: value,
      });
    }
  }
  const countriesOptions = getFormattedOptions(getOptionsList(datalist, 'country'));
  const statesOptions = getFormattedOptions(getOptionsList(datalist, 'state'));

  const changeCountries = values => {
    let val = values ? values : [];
    setCountriesState(val);
  };
  const changeStates = values => {
    let val = values ? values : [];
    setStatesState(val);
  };
  const changeMethods = values => {
    let val = values ? values : [];
    setMethodsState(val);
  };

  const handleSubmit = () => {
    sideSearchChangeFilters({
      range: range.value,
      countries: countriesState.map(item=>item.key),
      states: statesState.map(item=>item.key),
      methods: methodsState.map(item=>item.key),
    });
    setReset(false);
  };

  const handleSideBarSubmit = () => {
    handleSubmit();
    setHideSearchs(true);
  };

  const methodsDataList = useMethodsList();
  const methodsOptions = methodsDataList.edges.map( el => {
    return {'key': el.node.methodAbbreviation, 'value' : el.node.methodAbbreviation, 'label' : el.node.methodDescription};
  });

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: '3.3rem',
      marginTop: '.3rem'
    })
  };

  return (
    <>
      <label>
        Country/Region
        <Select
          isMulti
          name="countries"
          className="text-gray-700 focus:outline-none focus:shadow-outline"
          value={countriesState}
          options={countriesOptions}
          onChange={changeCountries}
          placeholder={'Any'}
          isSearchable={false}
          styles={customStyles}
        />
      </label>

      <label>
        State/Province
        <Select
          isMulti
          name="states"
          className="text-gray-700 focus:outline-none focus:shadow-outline"
          value={statesState}
          options={statesOptions}
          onChange={changeStates}
          placeholder={'Any'}
          isSearchable={false}
          styles={customStyles}
        />
      </label>

      <label>
        Method
        <Select
          isMulti
          name="methods"
          className="text-gray-700 focus:outline-none focus:shadow-outline"
          value={methodsState}
          options={methodsOptions}
          onChange={changeMethods}
          placeholder={'Any'}
          isSearchable={false}
          styles={customStyles}
        />
      </label>

      <button
        className="height-12 submit btn btn-green"
        onClick={handleSideBarSubmit}
        type="button"
      >
        Submit
      </button>
    </>
  );
};
