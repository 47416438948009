import React from 'react';
import { CSVLink } from 'react-csv';

export const ExportCsvButton: React.FC<{
  rows: any
}> = ({rows}) => {

  const yearAdjustedValues = (rows[0]) ? `Adjusted Values US$ ${rows[0].node.yearAdjustedValues}` : 'Adjusted Values US$ 2019';

  const headers = [
    'ID',
    'Type of habitat',
    'Service',
    yearAdjustedValues,
    'Units',
    'Country',
    'State',
    'Method',
    'Author',
    'Link to source'
  ];

  const data = rows.map((obj, index) => ([
    index + 1,
    obj.node.typeOfHabitat,
    obj.node.service,
    obj.node.adjustedValues,
    obj.node.units,
    obj.node.country,
    obj.node.state,
    obj.node.method,
    obj.node.author,
    obj.node.linkToSource,
  ]));

  var d = new Date();
  var datestring = ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate()).slice(-2) + d.getFullYear();
  var filename = 'BlueValue-Search-Results-' + datestring + '.csv';


  return (
    <CSVLink className="btn btn-thin btn-green-ghost btn-csv" data={data} headers={headers} filename={filename} title="Export as CSV">CSV</CSVLink>
  );
};
